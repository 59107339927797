import React, { createContext, useState, useContext, useEffect } from "react";

const VacanciesContext = createContext();
const isBrowser = typeof window !== "undefined";

export const useVacancies = () => useContext(VacanciesContext);

export const VacanciesProvider = ({ children }) => {
  const [vacanciesBeenViewed, setVacanciesBeenViewed] = useState(() => {
    if (isBrowser) {
      const hasBeenViewed = localStorage.getItem("vacanciesBeenViewed");
      return hasBeenViewed === "true";
    }
  });

  useEffect(() => {
    if (isBrowser) {
      const hasBeenViewed = localStorage.getItem("vacanciesBeenViewed");
      setVacanciesBeenViewed(hasBeenViewed === "true");
    }
  }, []);

  return (
    <VacanciesContext.Provider
      value={{ vacanciesBeenViewed, setVacanciesBeenViewed }}
    >
      {children}
    </VacanciesContext.Provider>
  );
};
