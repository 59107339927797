import React from "react";
import ReactDOM from "react-dom/client";
import { VacanciesProvider } from "./src/context/vacancies-context";
import { ServicesBlockProvider } from "./src/context/services-block-context";

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container);
    root.render(element);
  };
};

export const wrapRootElement = ({ element }) => (
  <ServicesBlockProvider>
    <VacanciesProvider>{element}</VacanciesProvider>
  </ServicesBlockProvider>
);