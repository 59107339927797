exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-candidates-js": () => import("./../../../src/pages/candidates.js" /* webpackChunkName: "component---src-pages-candidates-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-metaverse-js": () => import("./../../../src/pages/metaverse.js" /* webpackChunkName: "component---src-pages-metaverse-js" */),
  "component---src-pages-open-positions-js": () => import("./../../../src/pages/open-positions.js" /* webpackChunkName: "component---src-pages-open-positions-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-templates-blog-post-template-js": () => import("./../../../src/templates/blog-post-template.js" /* webpackChunkName: "component---src-templates-blog-post-template-js" */),
  "component---src-templates-first-blog-post-template-js": () => import("./../../../src/templates/first-blog-post-template.js" /* webpackChunkName: "component---src-templates-first-blog-post-template-js" */),
  "component---src-templates-open-position-template-js": () => import("./../../../src/templates/open-position-template.js" /* webpackChunkName: "component---src-templates-open-position-template-js" */)
}

