import React, { createContext, useState, useContext } from "react";

const ServicesBlockContext = createContext();

export const useServicesBlock = () => useContext(ServicesBlockContext);

export const ServicesBlockProvider = ({ children }) => {
  const [openBlock, setOpenBlock] = useState(null);

  return (
    <ServicesBlockContext.Provider value={{ openBlock, setOpenBlock }}>
      {children}
    </ServicesBlockContext.Provider>
  );
};
